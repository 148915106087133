// extracted by mini-css-extract-plugin
export var bgGrey100 = "PlainForm-module--bg-grey-100--5b352";
export var bgGrey150 = "PlainForm-module--bg-grey-150--1b5f2";
export var bgGrey200 = "PlainForm-module--bg-grey-200--56fa0";
export var bgGrey300 = "PlainForm-module--bg-grey-300--b1943";
export var bgGrey400 = "PlainForm-module--bg-grey-400--b3ead";
export var bgGrey500 = "PlainForm-module--bg-grey-500--b9920";
export var bgGrey600 = "PlainForm-module--bg-grey-600--aa445";
export var bgGrey700 = "PlainForm-module--bg-grey-700--f135f";
export var bgGrey800 = "PlainForm-module--bg-grey-800--be60a";
export var bgGrey900 = "PlainForm-module--bg-grey-900--9c48d";
export var checkboxClass = "PlainForm-module--checkboxClass--52d5a";
export var checkboxControlClass = "PlainForm-module--checkboxControlClass--458de";
export var checkboxInputClass = "PlainForm-module--checkboxInputClass--02254";
export var formControlClass = "PlainForm-module--formControlClass--7cd6a";
export var formStyles = "PlainForm-module--formStyles--e6eee";
export var successMsgClass = "PlainForm-module--successMsgClass--77f67";
export var textGrey100 = "PlainForm-module--text-grey-100--4c17a";
export var textGrey150 = "PlainForm-module--text-grey-150--2132a";
export var textGrey200 = "PlainForm-module--text-grey-200--6b180";
export var textGrey300 = "PlainForm-module--text-grey-300--8500d";
export var textGrey400 = "PlainForm-module--text-grey-400--7efe2";
export var textGrey500 = "PlainForm-module--text-grey-500--7ebf9";
export var textGrey600 = "PlainForm-module--text-grey-600--df156";
export var textGrey700 = "PlainForm-module--text-grey-700--8d8be";
export var textGrey800 = "PlainForm-module--text-grey-800--b8be2";
export var textGrey900 = "PlainForm-module--text-grey-900--faf15";