// extracted by mini-css-extract-plugin
export var bgGrey100 = "ContactForm-module--bg-grey-100--bcd1e";
export var bgGrey150 = "ContactForm-module--bg-grey-150--15455";
export var bgGrey200 = "ContactForm-module--bg-grey-200--bd76e";
export var bgGrey300 = "ContactForm-module--bg-grey-300--65151";
export var bgGrey400 = "ContactForm-module--bg-grey-400--61a2f";
export var bgGrey500 = "ContactForm-module--bg-grey-500--0cecf";
export var bgGrey600 = "ContactForm-module--bg-grey-600--e8414";
export var bgGrey700 = "ContactForm-module--bg-grey-700--c246a";
export var bgGrey800 = "ContactForm-module--bg-grey-800--9b6ee";
export var bgGrey900 = "ContactForm-module--bg-grey-900--d730f";
export var formStyles = "ContactForm-module--formStyles--b7b6c";
export var textGrey100 = "ContactForm-module--text-grey-100--33245";
export var textGrey150 = "ContactForm-module--text-grey-150--25eef";
export var textGrey200 = "ContactForm-module--text-grey-200--bc937";
export var textGrey300 = "ContactForm-module--text-grey-300--217f4";
export var textGrey400 = "ContactForm-module--text-grey-400--19b8a";
export var textGrey500 = "ContactForm-module--text-grey-500--74755";
export var textGrey600 = "ContactForm-module--text-grey-600--680e9";
export var textGrey700 = "ContactForm-module--text-grey-700--c2b3c";
export var textGrey800 = "ContactForm-module--text-grey-800--2dfb9";
export var textGrey900 = "ContactForm-module--text-grey-900--a49d1";