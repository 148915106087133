import React from "react"
import { Container } from "react-bootstrap"
import { Section, SectionHeading } from "../../UI/Common"
import PlainForm from "./PlainForm"
import * as style from "./ContactForm.module.scss"
import { dataLayerUpdate } from "../../../api/dataLayer/gaDataLayer"

const ContactFormPlain = ({ sectionHeading, paragraph, config, location, textAreaSuggestiveText, formId }) => {
  const formType = "PLAIN_FORM"
  dataLayerUpdate("showForm", {formType, location, formId})
  const formUrl = `${process.env.WORDPRESS_URL}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`
  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <Container className="text-center">
        {paragraph && <p className="pb-lg-3 text-left">{paragraph}</p>}
        <PlainForm
          formId={formId}
          formType={formType}
          location={location}
          formUrl={formUrl}
          textAreaLabel="What can we do for you?"
          className={style.formStyles}
        />
      </Container>
    </Section>
  )
}

export default ContactFormPlain
