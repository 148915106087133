import React, { useState, useCallback, useEffect } from "react"
import { Row, Col, Card, Form, Container, Button } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"
import Link from "../../UI/Common/Link"
import {
  formControlClass,
  checkboxClass,
  checkboxInputClass,
  checkboxControlClass,
  successMsgClass,
} from "./PlainForm.module.scss"
import {dataLayerUpdate} from "../../../api/dataLayer/gaDataLayer";

const PlainForm = ({
  formUrl,
  firstNameLabel="First Name*",
  lastNameLabel="Last Name*",
  emailAddressLabel="Email Address*",
  phoneNumberLabel="Phone Number",
  textAreaLabel="Your Message*",
  className="",
  formId,
  formType,
  location,
  product,
}) => {

  const [status, setStatus] = useState({ initial: true })
  const [validated, setValidated] = useState(false)
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i

  const validate = useCallback(
    event => {
      const form = event.currentTarget
      if (form.checkValidity() && email_regex.test(form.elements.email.value)) {
        setValidated(true)
      } else {
        setValidated(false)
        event.stopPropagation()
      }
    },
    [setValidated]
  )
  useEffect(() => {}, [validate])

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()

    if (validated) {
      dataLayerUpdate("formSubmission", {location, formType, formId, productData: product})
      const data = {
        firstName: form.elements.firstname.value,
        lastName: form.elements.lastname.value,
        email: form.elements.email.value,
        phone: form.elements.phone.value,
        message: `message content: ${form.elements.message.value}`
      }
      const postForm = async () => {
        try {
          await axios.post(`${formUrl}`, queryString.stringify(data))

          setStatus({
            success: process.env.CONTACT_FORM_SUCCESS_MESSAGE,
            initial: false,
          })
        } catch (exception) {

          setStatus({
            ...status,
            exception: `${exception.response.status}: ${exception.response.statusText}`,
          })
        }
      }
      postForm()
    }
  }

  return (
    <>
      <Container className={className}>
        {status && status.initial && (
          <Card body>
            <Form
              className="w-100"
              id="plainForm"
              noValidate
              onChange={validate}
              validated={validated}
              onSubmit={handleSubmit}
              method="post">
              <div className="p-lg-3">
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="firstname">
                      <Form.Label>{firstNameLabel}</Form.Label>
                      <Form.Control
                        className={formControlClass}
                        name="firstname"
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="lastname">
                      <Form.Label>{lastNameLabel}</Form.Label>
                      <Form.Control
                        className={formControlClass}
                        name="lastname"
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group controlId="email">
                      <Form.Label>{emailAddressLabel}</Form.Label>
                      <Form.Control
                        className={formControlClass}
                        name="email"
                        type="email"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-4">
                    <Form.Group controlId="phone">
                      <Form.Label>{phoneNumberLabel}*</Form.Label>
                      <Form.Control
                        required
                        className={formControlClass}
                        name="phone"
                        type="tel"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-lg-2">
                  <Col>
                    <Form.Group controlId="message">
                      <Form.Label>{textAreaLabel}*</Form.Label>
                      <Form.Control
                        className={formControlClass}
                        name="message"
                        as="textarea"
                        rows={4}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="pl-lg-3">
                  <Col className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <label
                      className={`${checkboxClass} form-check-label`}
                      htmlFor="terms">
                      <span className={checkboxInputClass}>
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                          required
                        />
                        <span className={checkboxControlClass}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false">
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="3"
                              d="M1.73 12.91l6.37 6.37L22.79 4.59"
                            />
                          </svg>
                        </span>
                      </span>
                      <div
                        className={`small d-flex flex-column flex-lg-row align-items-start`}>
                        <p className="text-left" style={{ maxWidth:'600px'}}>I declare that you are the legal owner of the vehicle and I have read and consented to the processing of information in accordance with our
                        <Link to="/privacy-policy"> Privacy policy</Link></p>
                      </div>
                    </label>
                    <Button id={formId} disabled={!validated} type="submit">
                  Submit
                </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Card>
        )}
        {status && status.success && (
          <Container className="pt-5 pb-5">
            <div className={successMsgClass}>{status.success}</div>
          </Container>
        )}
      </Container>
    </>
  )
}
export default PlainForm
