import { graphql } from "gatsby"
import React from "react"
import ContactFormPlain from "./ContactFormPlain"
export const fragment = graphql`
  fragment ContactFormPlainFragment on WpPage_Flexlayouts_FlexibleLayouts_ContactFormPlain {
    sectionHeading
    paragraph
    textAreaSuggestiveText
    formId
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFContactFormPlain = ({
  sectionHeading,
  paragraph,
  textAreaSuggestiveText,
  formId,
  config,
  location,
}) => {
  return (
    <ContactFormPlain
      sectionHeading={sectionHeading}
      paragraph={paragraph}
      config={config}
      location={location}
      textAreaSuggestiveText={textAreaSuggestiveText}
      formId={formId}
    />
  )
}
